import { IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import baseline_close from "../../provisional_icons/baseline-close.png";
import { useAdminService } from "../../contexts/super-admin-context";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { showProgressLine, hideProgressLine } from "../../redux/progress-line";
import { Role } from "./single-account";
import { useEventHandler } from "../../hooks/handle-events";
import baseline_delete from "../../provisional_icons/baseline-delete.png";
import { InternalUser } from "../../models/InternalUser";
import { accountListSelector } from "../../redux/account-list";
import StarsIcon from '@mui/icons-material/Stars';
import { useCustomModal } from "../modals/custom-message-modal";
import { RemoveUserModal } from "../modals/modal-content/remove-user-modal";
import baseline_delete_faded from "../../provisional_icons/baseline-delete-faded.png";
import { DeleteSinglePMModal } from "../modals/modal-content/delete-single-pm-modal";
import { ChangeRoleLastPmModal } from "../modals/modal-content/change-role-last-pm-modal";
import { roleMultiSelectRenderValue } from "../../services/utils";
import { CIM_ROLES, CIMRoleKey } from "../../constants";

export function UsersSuperAdmin() {
    const [searchValue, setSearchValue] = useState("");
    const [searchedUsers, setSearchedUsers] = useState<InternalUser[]>([]);
    const { getAllUsers, addAccountUserRole, removeAccountUserRole, allUsers, usersGroupedByAccount, getAccounts } = useAdminService();
    const dispatch = useAppDispatch();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const accounts = useAppSelector((state) => accountListSelector(state));
    const { showModal } = useCustomModal();

    const onClick = async (role: Role, userId: number, roleExists: boolean, accountId: string) => {
        dispatch(showProgressLine());
        if (!roleExists) {
            await addAccountUserRole(userId, role, accountId);

        } else {
            await removeAccountUserRole(userId, role, accountId);
        }
        getAllUsers();
        dispatch(hideProgressLine());
    }

    const getAccount = (accountId: string) => accounts?.find(a => a.id === accountId);

    useEffect(() => {
        const results = allUsers.filter(au => au.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            au.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            getAccount(au.accountId!)?.name.toLocaleLowerCase()?.includes(searchValue.toLocaleLowerCase())
        ).filter(r => r.deleted === false);
        setSearchedUsers(results);
    }, [searchValue, allUsers])

    useEffect(() => {
        getAccounts();
        getAllUsers();
    }, [])

    const isLastPm = (accountId: string, isPM: boolean) => ((usersGroupedByAccount[(accountId as unknown) as number]) ?? []).filter(u =>
        (u.role === "PM" || u.accountRoles!.some(role => role === "PM")) && isPM).length === 1;

    return <div className="page-wrapper">
        <div className="page-header">Users</div>
        <div style={{ fontSize: 17, letterSpacing: 0.49 }}>
            All the users registered on the RLS platform, except the super admins will be listed in the table below.<br />
            User with multi-account access are highlighted with a star icon in front of them.<br />
            Removed users can be reactivated by accessing their linked account under the Removed users section in Account details.
        </div>
        <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for name, email or account"
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color="secondary" />
                    </InputAdornment>
                ),
                endAdornment: (
                    searchValue && <InputAdornment position="end">
                        <IconButton onClick={() => setSearchValue("")}>
                            <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            style={{ width: 325, marginTop: 24 }}
            inputProps={{ style: { height: 35 } }}
        />
        <table width="90%" className="table" style={{ marginTop: 24 }}>
            <thead>
                <tr className="table-header">
                    <td className="table-header-name">NAME</td>
                    <td></td>
                    <td>EMAIL</td>
                    <td>ACCOUNT</td>
                    <td>ROLE</td>
                    <td></td>
                </tr>
            </thead>
            <tbody className="table-body">
                {!!searchedUsers.length && searchedUsers.map(user => <tr key={String(user.id) + user.accountId} className="table-entry">
                    <td className="table-body-name" style={{ maxWidth: 130, display: "flex", alignItems: "center" }}>
                        {user.role === "AM" && <Tooltip title="Multi-account access">
                            <StarsIcon style={{ marginRight: 6, color: "#FCAF17", width: 20, height: 20 }} />
                        </Tooltip>}
                        {user.name ? user.name : "-"}
                    </td>
                    <td className="table-body-state" style={{ width: 90 }} >
                        {!user.name ? <span className="pending-state">Pending</span> : <></>}
                    </td>
                    <td className="table-body-email" style={{ width: 250 }}>{user.email}</td>
                    <td style={{ paddingRight: 20, width: 170 }}>
                        {user.tenant?.name}
                    </td>
                    <td>
                        <Select
                            value={user.accountRoles}
                            multiple={true}
                            disableUnderline
                            variant="standard"
                            renderValue={roleMultiSelectRenderValue}
                            onClick={isLastPm(user.accountId!, (user.role === "PM" || user.accountRoles!.some(role => role === "PM"))) ?
                                () => showModal(ChangeRoleLastPmModal, {}) : () => { }}
                            disabled={isLastPm(user.accountId!, (user.role === "PM" || user.accountRoles!.some(role => role === "PM")))}
                            defaultValue={user.accountRoles}
                        >
                            <MenuItem
                                value={"ADMIN"}
                                style={{ backgroundColor: "white" }}
                                onClick={e => onClick("ADMIN", user.id, user.accountRoles!.some(role => role==="ADMIN"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="ADMIN")} style={{marginRight:10}}/>Administrator</MenuItem>
                            <MenuItem
                                value={"PM"}
                                style={{ backgroundColor: "white" }}
                                onClick={e => onClick("PM", user.id, user.accountRoles!.some(role => role==="PM"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="PM")} style={{marginRight:10}}/>Project Manager</MenuItem>
                            <MenuItem
                                value={"FM"}
                                style={{ backgroundColor: "white" }}
                                onClick={e => onClick("FM", user.id, user.accountRoles!.some(role => role==="FM"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="FM")} style={{marginRight:10}}/>File Manager</MenuItem>
                            <MenuItem
                                value={"ANONYMIZE"}
                                onClick={e => onClick("ANONYMIZE", user.id, user.accountRoles!.some(role => role==="ANONYMIZE"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="ANONYMIZE")} style={{marginRight:10}}/>Anonymize</MenuItem>
                            <MenuItem
                                value={"REDACTION"}
                                onClick={e => onClick("REDACTION", user.id, user.accountRoles!.some(role => role==="REDACTION"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="REDACTION")} style={{marginRight:10}}/>Redaction</MenuItem>
                            <MenuItem
                                value={"USER"}
                                onClick={e => onClick("USER", user.id, user.accountRoles!.some(role => role==="USER"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="USER")} style={{marginRight:10}}/>Member</MenuItem>
                                {Object.keys(CIM_ROLES).map((roleKey)=>{
                                    return <MenuItem
                                    key={roleKey}
                                    onClick={e => onClick(roleKey as CIMRoleKey, user.id, user.accountRoles!.some(role => role===roleKey),user.accountId!)}
                                    value={roleKey}
                                    onMouseEnter={onMouseEventHandler}
                                    onMouseLeave={onLeaveMouseEventHandler}>
                                    <input type={"checkbox"} readOnly={true}
                                        checked={user.accountRoles?.some(role => role===roleKey)} style={{marginRight:10}}/>{CIM_ROLES[roleKey as CIMRoleKey]}</MenuItem>
                                    })  
                                }
                            <MenuItem
                                value={"VIEW"}
                                onClick={e => onClick("VIEW", user.id, user.accountRoles!.some(role => role==="VIEW"), user.accountId!)}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="VIEW")} style={{marginRight:10}}/>View Only</MenuItem>
                        </Select>
                    </td>
                    <td className="table-delete-item" style={{}}>
                        {!isLastPm(user.accountId!, (user.role === "PM" || user.accountRoles!.some(role => role === "PM"))) ?
                            <img alt="delete" src={baseline_delete} className="delete-item" onClick={() =>
                                showModal(() => RemoveUserModal(accounts.find(a => a.id === user.accountId)!, user, getAllUsers), {})} /> :
                            <img alt="delete" src={baseline_delete_faded} className="delete-item" onClick={() =>
                                showModal(() => DeleteSinglePMModal(), {})} />
                        }
                    </td>
                </tr>)}
                {!searchedUsers.length && <tr className="table-entry">
                    <td style={{ padding: 15, color: "#939598", fontStyle: "italic", fontSize: 15 }}>No matches found</td>
                </tr>}
            </tbody>
        </table>
    </div>
}
