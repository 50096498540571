import React, {useRef, useEffect, useState} from 'react';
import WebViewer from '@pdftron/webviewer';
import {Buttons, CompareModalBody, CustomModal} from "./utils/webviewer-modal-button-utils";
import {comparePanelSvg, differenceViewIcon, saveSVGIcon, switchFilesIcon} from "./utils/svg-files";
import WebViewerCompareView from "./utils/webviewer-compare-view";
import {getApiFilesByProjectId, getFileTagByProjectIdAndFileName, putApiFileById} from "../services/files";
import {getFileNameFromURL} from "../pages/user/projects/single-project";
import {Button} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hook";
import {useNavigate, useParams} from "react-router-dom";
import {UpdateFileTagModal} from "../pages/modals/modal-content/update-file-tag-modal";
import {useCustomModal} from "../pages/modals/custom-message-modal";
import {showSnackbar} from "../redux/snackbar";
import {startUploadingToS3, uploadFileUsingS3} from "./file-management";
import {filterPDFExtension} from "./files-component";
import {useAuthService} from "../contexts/auth-context";
import {uploadLogsToS3} from "./webviewer";
import WebviewerCompareView from "./utils/webviewer-compare-view";
import CompareVisulaizeData from "../pages/modals/compare-visulaize-data";

let temporaryFilesHolder: string[] = [];
let retryingCount = 1;

type log = {
    date: string,
    time: string,
    document: string,
    user?: string,
    roles?: string[],
    action: string,
    annotationType: string,
    pages: number[]
}

const CompareViewer = (p: { files: any[], patternSetID?: number, projectID?: number, taskId?: number,shouldCompareVersion?:boolean, initialTaskStatus?: string, isRestoring? : boolean, tag?: string}) => {
    const params = useParams();
    const { projectId } = params;
    const { showModal, hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    const viewer = useRef(null);
    const [showingProgressSpinner, setShowingProgressSpinner] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([])
    const webviewerCompareViewRef = useRef(new WebViewerCompareView());
    const { loaded, projects } = useAppSelector(state => state.projects)
    const activeProject = projects.find((val) => val.id === p.projectID);
    const nav = useNavigate();
    let fileNameAndIdMap = new Map<string, string>();

    const loadFiles = async ()=> {
        const files = await getApiFilesByProjectId(p.projectID!)
        let filesArray = []
        if(p.shouldCompareVersion){
            filesArray = JSON.parse(localStorage.getItem("versionUrls")!)
        }else {
            for (let i = 0; i < p.files.length; i++) {
                const index = files.findIndex((file: any) => getFileNameFromURL(file.url) === getFileNameFromURL(p.files[i]));
                if (index > -1) {
                    // @ts-ignore
                    fileNameAndIdMap.set(getFileNameFromURL(files[index].url) as string, files[index].id)
                    if (p.isRestoring) {
                        filesArray.push(p.files[i]);
                    } else {
                        filesArray.push(files[index].url) //use the URL from the most recent API call, because these AWS URLs expire after 15 minutes.
                    }
                }
            }
        }
        setSelectedFiles(filesArray)
    }

    const compareReportModal = (data: any) => {
        showModal(CompareVisulaizeData, {
            data,
            onExport: async () => {
                hideModal()
            },
            onSave: async (file: File) => saveToFileManagement(file)
        })
    }

    function saveToFileManagement(file: File) {
        showProgressSpinner();
        hideModal();
        uploadFileUsingS3(file, projectId ? +projectId : -1, auth.loginInfo?.tenant?.user?.id, false, false).then(async (r) => {
            await putApiFileById(JSON.stringify(r?.id),
                {
                    id: r?.id,
                    name: file.name,
                    size: file.size,
                    category: "Comparison",
                    projectId: Number(p.projectID),
                    isDocOpen: false,
                    createdByName: auth.loginInfo?.tenant?.user?.name || ""
                });
            setShowingProgressSpinner(false);
            window.open('/app/user/workflow/projects/' + projectId + '/file-management');
        }).catch((e) => {
            setShowingProgressSpinner(false);
            window.dispatchEvent(new Event('processEnd'))
            dispatch(showSnackbar({ message: "Error saving report", type: "error" }));
        })
    }

    useEffect(() => {
        const loadCompareFiles = async () => {
            await loadFiles();
        }
        loadCompareFiles();
    }, []);

    const showProgressSpinner = () => {
        setShowingProgressSpinner(true);
        window.dispatchEvent(new Event('processStarted'))
    }

    // if using a class, equivalent of componentDidMount
    useEffect(() => {
        if(selectedFiles.length > 0) {
            webviewerCompareViewRef?.current?.setFiles(selectedFiles)
            WebViewer.WebComponent(
                {
                    licenseKey: process.env.REACT_APP_PDFTRON_LICENSE,
                    path: '/webviewer/lib',
                    fullAPI: true,
                    isAdminUser: true,
                    enableRedaction: false,
                    disabledElements: [
                        'redactionPanel',
                        'fullScreenButton',
                        'toolbarGroup-Shapes',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Forms',
                        'toolbarGroup-FillAndSign',
                        'toolbarGroup-Annotate',
                        'toolbarGroup-Edit',
                    ]
                },
                viewer.current!,
            ).then((instance) => {
                const {UI, Core} = instance
                const {Tools} = Core
                webviewerCompareViewRef?.current?.setInstance(instance);
                const domDoc = document.getElementsByTagName('apryse-webviewer')[0].shadowRoot
                let logs: log[] = [];

                const getDecodedFileName = (): string => {
                    const name = Core.documentViewer?.getDocument()?.getFilename().split('?')[0];
                    return decodeURIComponent(localStorage.getItem("versionUrls") ? name?.split("*")[2].trim() : name)
                }
                const hideProgressSpinner = () => {
                    setShowingProgressSpinner(false);
                    window.dispatchEvent(new Event('processEnded'));

                }
                const stopSaving = () => {
                    hideProgressSpinner();
                    window.dispatchEvent(new Event('processEnded'))
                }

                const saveFile = async (fileName: string, existingFileSave: boolean, temporaryLocalChangesSave: boolean, updatedFile?: any, saveTag?: string): Promise<string | undefined> => {
                    showProgressSpinner();
                    const fileId = fileNameAndIdMap.get(fileName);
                    //turn off the inactivity timer
                    window.dispatchEvent(new Event('processStarted'))

                    const doc = Core.documentViewer.getDocument();
                    await doc.getDocumentCompletePromise();
                    const xfdfString = await Core.annotationManager.exportAnnotations({fields: true});
                    let data;
                    try {
                        data = await doc.getFileData({
                            includeAnnotations: true,
                            xfdfString: xfdfString,
                            flags: Core.PDFNet.SDFDoc.SaveOptions.e_linearized
                        });
                    } catch (e) {

                        if (retryingCount === 1) {
                            await saveFile(fileName, existingFileSave, temporaryLocalChangesSave, updatedFile, saveTag)
                        } else {
                            console.log(e);
                            dispatch(showSnackbar({message: "Error getting file data", type: "error"}));
                            stopSaving();
                        }
                        retryingCount++;
                        return;
                    }
                    //This string contains all the information about annotations in the document.
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr]);

                    const file = new File([blob], fileName, {type: 'application/pdf'})
                    //We can be sure project ID is not null because we disable the save buttons when accessing the webviewer not from a project

                    if (temporaryLocalChangesSave) {
                        return startUploadingToS3(updatedFile ? updatedFile : file, new AbortController().signal, p.projectID!, auth.loginInfo?.tenant?.user?.id, true, true, fileId as string, saveTag, temporaryLocalChangesSave).then(() => {
                            return "success";
                        }).catch(() => {
                            stopSaving();
                            return undefined
                        })
                    }

                    return startUploadingToS3(updatedFile ? updatedFile : file, new AbortController().signal, p.projectID!, auth.loginInfo?.tenant?.user?.id, true, true, fileId as string, saveTag, false,).then(() => {
                        const currentDocumentLogs = logs.filter(log => log.document === fileName)
                        //@ts-ignore
                        uploadLogsToS3(currentDocumentLogs, filterPDFExtension(fileName), p.projectID.toString())
                        logs = []
//                  logs = logs.filter(log => log.document !== fileName)
                        if (p.isRestoring) {
                            dispatch(showSnackbar({message: `Successfully restored ${fileName}`, type: "info"}));
                        } else {
                            dispatch(showSnackbar({message: `Successfully saved ${fileName}`, type: "info"}));
                        }

                        stopSaving();
                        hideModal()
                        return undefined;
                    }).catch((e) => {
                        dispatch(showSnackbar({message: "Error Saving File!", type: "error"}));
                        stopSaving();
                        return undefined;
                    })

                }

                const saveFileInPlace = async (showModalFlag: boolean) => {
                    const filename = getDecodedFileName()
                    if (showModalFlag) {
                        const tag = await getFileTagByProjectIdAndFileName(p.projectID, filename)
                        let updatedTag = tag.tag;
                        await showModal(UpdateFileTagModal, {
                            projectId: p.projectID,
                            fileName: filename,
                            fileId: fileNameAndIdMap.get(filename),
                            tag: updatedTag,
                            updateTag: async (tag) => {
                                try {
                                    const updatedData = undefined
                                    updatedTag = tag;
                                    retryingCount = 1;
                                    await saveFile(filename, true, false, updatedData, updatedTag)
                                } catch {

                                }
                            }
                        })
                    } else {
                        retryingCount = 1;
                        await saveFile(getDecodedFileName(), true, false, undefined, p.tag)
                    }
                }

                const handleSaveCompare = () => {
                    //instance.UI.closeElements(['MainMenuFlyout']);
                    saveFileInPlace(true);
                }
                const handleSwitchCompareFiles = () => {
                    const rearrangedFiles = temporaryFilesHolder.length > 0 ? [temporaryFilesHolder[1], temporaryFilesHolder[0]] : [selectedFiles[1], selectedFiles[0]];
                    const names = JSON.parse(localStorage.getItem("versionFileNames")!);
                    const rearrangedNames = names && names.length > 0 ? [names[1],names[0] ] : [];
                    localStorage.setItem("versionFileNames", JSON.stringify(rearrangedNames));
                    temporaryFilesHolder = rearrangedFiles;
                    webviewerCompareViewRef?.current?.setFiles(rearrangedFiles)
                    WebViewerCompareView.processFiles(comparePanelToggleButton)
                    processFilesCompare();
                }
                async  function handleDifferenceView (){
                    await WebviewerCompareView.createNewFileWithDifferences()
                }
                if (p.projectID) {
                    const customSwitchButton = new Buttons("", "switchFiles", handleSwitchCompareFiles, switchFilesIcon).setTitle("Click to switch files")
                    const switchButton = new instance.UI.Components.CustomButton(customSwitchButton);
                    const differenceViewButton = new instance.UI.Components.CustomButton(new Buttons("", "difference", handleDifferenceView, differenceViewIcon).setTitle("Click to view difference document").setClassName("active"));
                    const defaultHeaders = instance.UI.getModularHeader('default-top-header')
                    // @ts-ignore
                    const copyOfItems = defaultHeaders.items.slice();
                    // @ts-ignore
                    copyOfItems.splice((defaultHeaders.items.length - 3),0,switchButton,differenceViewButton);
                    defaultHeaders.setItems([...copyOfItems]);

                    const defaultHeader = UI.Flyouts.getFlyout("MainMenuFlyout")
                    const saveButton = new Buttons("Save", "customSaveButton", handleSaveCompare, saveSVGIcon).setTitle("Save")
                    // @ts-ignore
                    const items = defaultHeader.properties.items;
                    const position = 4;
                    items.splice(position, 1, saveButton);
                    defaultHeader.setItems([...items]);

                }
                let interval: any;
                interval = setInterval(()=>{
                    const compareButton: HTMLElement = domDoc!.querySelector<HTMLElement>('[data-element="comparePanelToggle"]') as HTMLElement;
                    if(compareButton){
                        compareButton.addEventListener("click", ()=>{
                           if(!instance.UI.isElementOpen('comparePanel')){
                               addReportButton()
                           }
                        });
                        clearInterval(interval);
                    }
                }, 1000);

                function addReportButton(){
                    let interval: any;
                    interval = setInterval(()=>{
                        const comparePanel: HTMLElement = domDoc!.querySelector<HTMLElement>('[data-element="comparePanel"]') as HTMLElement;
                        if(comparePanel){
                            WebViewerCompareView.appendButton()
                            clearInterval(interval);
                        }
                    }, 100);
                }

                //compare custom modal and button
                const compareProcessingModal = new CustomModal("process-modal", true)
                    .addHeader({title: 'Compare Status', className: "text-center"})
                    .addBody(CompareModalBody.displayFiles(), {}).addFooter(instance)
                // @ts-ignore
                instance.UI.addCustomModal(compareProcessingModal)
                const comparePanelToggleButton = new Buttons("", "compare-toggle-button", () => {
                    if (instance.UI.isElementOpen('comparePanel')) {
                        instance.UI.closeElements(['comparePanel'])
                        comparePanelToggleButton.makeInActive(domDoc)
                    } else {
                        instance.UI.openElements(['comparePanel'])
                        comparePanelToggleButton.makeActive(domDoc)
                    }
                }, comparePanelSvg)
                const processFilesCompare = () => {
                    instance.UI.openElements(['process-modal'])
                    instance.UI.addEventListener(instance.UI.Events.MULTI_VIEWER_READY, () => WebViewerCompareView.processFiles(comparePanelToggleButton));
                    instance.UI.enterMultiViewerMode();

                }
                processFilesCompare();

                instance.Core.documentViewer.addEventListener('open',(data: any)=>compareReportModal(data));

            });
        }
    }, [selectedFiles]);

    return (
        <div className="body-container" style={{flexDirection: "column", width: '100%'}}>
            <div style={{display: 'flex', margin: '0 0 0 0', gap: '2.3rem', height: '32px'}}>
                <Button style={{textDecoration: 'underline', color: "blue"}}
                        onClick={() => nav(`/app/user/workflow/projects/${activeProject?.id}`)}>Back
                    to {activeProject?.name}</Button>
            </div>
            <div className="DocViewer">
                <div className="webviewer" ref={viewer} style={{ height: "calc(100vh - 154px)", width: '70%', display: 'inline-block' }}></div>
            </div>
            {showingProgressSpinner  && <div className="progress-modal"><div className="spinner spinner-position-center"></div></div>}
        </div>
    );
};

export default CompareViewer;
