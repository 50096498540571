import { useEffect, useRef, useState } from "react";
import { Controller, FieldError, SubmitHandler, useForm } from "react-hook-form";
import { useAdminService } from "../../contexts/super-admin-context";
import { EmailErrors, useValidation } from "../../hooks/validation";
import baseline_arrow_back from '../../provisional_icons/baseline-arrow-back.png';
import { AccountModal } from "../modals/account-modal";
import { useQuery } from '../../hooks/utils';
import { useEffectAsync } from "../../hooks/async-effect";
import { Account } from "../../models/Account";
import { useNavigate } from "react-router";
import './single-account.scss';
import { ErrorHelper } from "../../components/error-helper";
import baseline_delete from "../../provisional_icons/baseline-delete.png";
import baseline_delete_faded from "../../provisional_icons/baseline-delete-faded.png";
import baseline_close from "../../provisional_icons/baseline-close.png";
import { InternalUser } from "../../models/InternalUser";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useCustomModal } from "../modals/custom-message-modal";
import { RenameAccountWizard } from "./wizards/rename-account-wizard";
import { RemoveAccountModal } from "../modals/modal-content/remove-account-modal";
import { RemoveUserModal } from "../modals/modal-content/remove-user-modal";
import { useEventHandler } from "../../hooks/handle-events";
import { DeleteSinglePMModal } from "../modals/modal-content/delete-single-pm-modal";
import { MoreActionsComponent } from "../../components/more-actions-component";
import { useAuthService } from "../../contexts/auth-context";
import { useAppDispatch } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Avatar, Box } from "@mui/material";
import { getUserInitials, roleNameGenerator, roleMultiSelectRenderValue } from "../../services/utils";
import { postApiRlsUsersAssignAccount } from "../../services/rls.user";
import { showSnackbar } from "../../redux/snackbar";
import { ReactivateUserModal } from "../modals/modal-content/reactivate-user-modal";
import { UserAlreadyExistsModal } from "../modals/modal-content/user-already-exists-modal";
import { ChangeRoleLastPmModal } from "../modals/modal-content/change-role-last-pm-modal";
import { CIM_ROLES, CIMRoleKey } from "../../constants";

export type Role = "SUPER" | "AM" | "ADMIN" | "PM" | "FM" | "USER" | "ANONYMIZE" | "REDACTION" | "VIEW" | "CIM_APPROVER" | "CIM_REVIEWER" | "CIM_MANAGER";

type InviteInfo = {
    email: string,
    roles: Role[]
}

type AssignAMInfo = {
    email: string,
    roles: Role[]
}

export function RoleTableInAccount(p: { account: Account, isSuper: boolean, isDisabled: boolean }) {
    const dispatch = useAppDispatch();
    const [ searchValue, setSearchValue ] = useState("");
    const [ roleFilter, setRoleFilter ] = useState<string>("");
    const [ activeFilter, setActiveFilter ] = useState<string>("ACTIVE");
    const [ filteredUsers, setFilteredUsers ] = useState<InternalUser[]>([]);
    const { showModal } = useCustomModal();
    const { addAccountUserRole, removeAccountUserRole, allAccountUsers } = useAdminService();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const taskRef = useRef(null);

    useEffect(() => {
        const results = allAccountUsers.filter(au => au.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            au.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        ).filter(au => au.accountRoles?.find(role => roleFilter==="" ? true : role.toString() === roleFilter)
        ).filter(au => activeFilter==="ALL" ? true : activeFilter==="ACTIVE" ? au.deleted === false : au.deleted === true)
            .sort((a, b) => {
                //sort by name
                if (a.name && !b.name) return -1
                else if (!a.name && b.name) return 1
                else if (a.name && b.name) return a.name.localeCompare(b.name)
                return a.email.localeCompare(b.email)
            });
        setFilteredUsers(results);
    }, [searchValue, roleFilter, activeFilter, allAccountUsers])

    const onClick = async (role: Role, userId: number, roleExists: boolean) => {
        dispatch(showProgressLine());
        if (!roleExists) {
            await addAccountUserRole(userId, role, p.account.id);
        } else {
            await removeAccountUserRole(userId, role, p.account.id);
        }
        dispatch(hideProgressLine());
    }

    const disabledText = "#939598";

    const isSingleProjectManager = (allAccountUsers.filter(user => user.role === "PM" || user.accountRoles?.some(role => role === "PM")).length===1) ?? false;

    return <div>
        <div
            style={{ marginTop: 10 }}
        >
            <TextField
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search for name or email"
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="secondary" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        searchValue && <InputAdornment position="end">
                            <IconButton onClick={() => setSearchValue("")}>
                                <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                style={{ width: 325, marginTop: 0, marginRight:10}}
                inputProps={{ style: { height: 35 } }}
            />
            <Select
                defaultValue={roleFilter}
                value={roleFilter}
                displayEmpty
                onChange={e => setRoleFilter(e.target.value)}
                style={{width: 170, marginRight:10, backgroundColor: "white"}}
            >
                <MenuItem
                    value={""}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>All Roles</MenuItem>
                <MenuItem
                    value={"ADMIN"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Administrator</MenuItem>
                <MenuItem
                    value={"PM"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Project Manager</MenuItem>
                <MenuItem
                    value={"FM"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>File Manager</MenuItem>
                <MenuItem
                    value={"USER"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Member</MenuItem>
                <MenuItem
                    value={"VIEW"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>View Only</MenuItem>
                <MenuItem
                    value={"REDACTION"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Redaction</MenuItem>
                <MenuItem
                    value={"ANONYMIZE"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Anonymize</MenuItem>

                {Object.keys(CIM_ROLES).map((roleKey)=>{
                    return <MenuItem
                    key={roleKey}
                    value={roleKey}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    {CIM_ROLES[roleKey as CIMRoleKey]}</MenuItem>
                })  
                }
            
            </Select>
            <Select
                value={activeFilter}
                onChange={e => setActiveFilter(e.target.value)}
                sx={{ cursor: "pointer !important" }}
            >
                <MenuItem
                    value={"ALL"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>All users</MenuItem>
                <MenuItem
                    value={"ACTIVE"}
                    style={{ backgroundColor: "white" }}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Active users</MenuItem>
                <MenuItem
                    value={"INACTIVE"}
                    style={{ backgroundColor: "white" }}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>Inactive Users</MenuItem>
            </Select>
        </div>

    <div className="table-responsive">
    <table width="90%" className="table">
        <thead>
            <tr className="table-header">
                <td className="table-header-name">NAME</td>
                <td></td>
                <td>EMAIL</td>
                <td>ROLE</td>
                <td></td>
            </tr>
        </thead>
        <tbody className="table-body">
            {filteredUsers.map(user => <tr ref={taskRef} key={user.id} className="table-entry">
                <td className="table-body-name" style={{ color: user.deleted ? disabledText : "initial" }}>
                    {user.name ? user.name : "-"}</td>
                <td className="table-body-state" >
                    {!user.name ? <span className="pending-state">Pending</span> : <></>}
                </td>
                <td className="table-body-email" style={{ color: user.deleted ? disabledText : "initial" }}>{user.email}</td>
                <td className="table-body-role">
                    {!user.deleted ? <Select
                        value={user.accountRoles!}
                        multiple={true}
                        disableUnderline
                        variant="standard"
                        color="secondary"
                        style={{ backgroundColor: "white" }}
                        renderValue={roleMultiSelectRenderValue}
                        disabled={(!p.isSuper && user.role === "ADMIN")}
                        sx={{ cursor: "pointer !important" }}
                    >
                        {(p.isSuper || (!p.isSuper && user.role === "ADMIN")) &&
                            <MenuItem
                                value={"ADMIN"}
                                onClick={e => onClick("ADMIN", user.id, user.accountRoles!.some(role => role==="ADMIN"))}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                       checked={user.accountRoles?.some(role => role==="ADMIN")} style={{marginRight:10}}/>Administrator</MenuItem>
                        }
                        <MenuItem
                            value={"PM"}
                            disabled={isSingleProjectManager && user.accountRoles?.some(role => role==="PM")}
                            onClick={e => onClick("PM", user.id, user.accountRoles!.some(role => role==="PM"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="PM")} style={{marginRight:10}}/>Project Manager</MenuItem>
                        <MenuItem
                            value={"FM"}
                            disabled={isSingleProjectManager && user.accountRoles?.some(role => role==="FM")}
                            onClick={e => onClick("FM", user.id, user.accountRoles!.some(role => role==="FM"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="FM")} style={{marginRight:10}}/>File Manager</MenuItem>
                        <MenuItem
                            value={"ANONYMIZE"}
                            onClick={e => onClick("ANONYMIZE", user.id, user.accountRoles!.some(role => role==="ANONYMIZE"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="ANONYMIZE")} style={{marginRight:10}}/>Anonymize</MenuItem>
                        <MenuItem
                            value={"REDACTION"}
                            onClick={e => onClick("REDACTION", user.id, user.accountRoles!.some(role => role==="REDACTION"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="REDACTION")} style={{marginRight:10}}/>Redaction</MenuItem>
                        <MenuItem
                            value={"USER"}
                            onClick={e => onClick("USER", user.id, user.accountRoles!.some(role => role==="USER"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="USER")} style={{marginRight:10}}/>Member</MenuItem>
                            {Object.keys(CIM_ROLES).map((roleKey)=>{
                                return <MenuItem
                                key={roleKey}
                                onClick={e => onClick(roleKey as CIMRoleKey, user.id, user.accountRoles!.some(role => role===roleKey))}
                                value={roleKey}
                                disabled={!p?.account?.accessToCIM}
                                onMouseEnter={onMouseEventHandler}
                                onMouseLeave={onLeaveMouseEventHandler}>
                                <input type={"checkbox"} readOnly={true}
                                        checked={user.accountRoles?.some(role => role===roleKey)} style={{marginRight:10}}/>{CIM_ROLES[roleKey as CIMRoleKey]}</MenuItem>
                            })  
                            }
                        <MenuItem
                            value={"VIEW"}
                            onClick={e => onClick("VIEW", user.id, user.accountRoles!.some(role => role==="VIEW"))}
                            onMouseEnter={onMouseEventHandler}
                            onMouseLeave={onLeaveMouseEventHandler}>
                            <input type={"checkbox"} readOnly={true}
                                   checked={user.accountRoles?.some(role => role==="VIEW")} style={{marginRight:10}}/>View Only</MenuItem>
                    </Select> : <span style={{ color: disabledText }}>{roleNameGenerator(user)}</span>}
                </td>
                <td className="table-delete-item" style={user.deleted ? { width: 205 } : {}}>
                    {!user.deleted ?
                        !p.isSuper && user.role === "ADMIN" ?
                            <img alt="delete" src={baseline_delete_faded} className="delete-item" />
                            : (isSingleProjectManager && user.accountRoles?.some(role => role==="PM")) ?
                                <img alt="delete" src={baseline_delete_faded} className="delete-item" onClick={() => showModal(() => DeleteSinglePMModal(), {})} /> :
                                <img alt="delete" src={baseline_delete} className="delete-item" onClick={() => showModal(() => RemoveUserModal(p.account, user), {})} />
                        :
                        <Button size="small" variant="outlined" color="secondary"
                            onClick={() => showModal(ReactivateUserModal, { user })}>REACTIVATE USER</Button>}
                </td>
            </tr>)}
        </tbody>
    </table>
    </div>
    </div>
}

export function SingleAccountPage() {
    const { register, handleSubmit, formState: { errors }, getValues, resetField, watch, reset } = useForm<InviteInfo>();
    const { register: registerAM, handleSubmit: handleSubmitAM, formState: { errors: errorsAM }, getValues: getValuesAM,
        watch: watchAM, reset: resetAM, control: controlAM } = useForm<AssignAMInfo>();
    const theme = useTheme();
    const { getAccountById, sendInvite, editAccountAccessToDocs, users, currentAccount, getUsersFromSpecificAccount, editAccountAccessToCIM } = useAdminService();
    const auth = useAuthService();
    const query = useQuery();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const { signInExpressions } = useValidation();
    const { showModal } = useCustomModal();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isEmailFieldEmpty, setIsEmailFieldEmpty] = useState(true);
    const [rolesNewAccount, setRolesNewAccount] = useState<string[]>([]);
    const [rolesAccountManagerInvite, setRolesAccountManagerInvite] = useState<string[]>([]);
    const [accessToDocs, setAccessToDocs] = useState(false);
    const [accessToCIM, setAccessToCIM] = useState(false);
    const autoClearRef = useRef<HTMLElement | null>(null);

    const allUsers = users?.admins.concat(users.members).concat(users.projectManagers);
    const deletedUsers = allUsers?.filter(u => u.deleted === true);

    const onSubmit: SubmitHandler<InviteInfo> = (data) => {
        if (allUsers?.find(au => au.email.trim() === data.email.trim())) {
            showModal(UserAlreadyExistsModal, {
                title: "This user has already been invited to this account.",
                content: "This email address has already been used to send out an invitation to this account."
            });
        } else {
            dispatch(showProgressLine());
            sendInvite({ email: getValues("email").trim(), accountRoles: getValues("roles"),  accountId: currentAccount?.id! });
            setRolesNewAccount([]);
            setRolesAccountManagerInvite([]);
            reset();
            dispatch(hideProgressLine());
        }
    }

    const onSubmitAM: SubmitHandler<AssignAMInfo> = (data) => {
        dispatch(showProgressLine());
        postApiRlsUsersAssignAccount({
            accountId: currentAccount?.id!,
            email: data.email.trim(),
            accountRoles: getValuesAM("roles")
        })
            .then(() => {
                dispatch(showSnackbar({
                    message: `${data.email} has been added to ${currentAccount?.name} account`,
                    type: "info"
                }))
                getUsersFromSpecificAccount(currentAccount?.id!);
            })
            .catch(e => {
                dispatch(showSnackbar({
                    message: "Error sending invite!", type: "error"
                }))
            });
        setRolesNewAccount([]);
        setRolesAccountManagerInvite([]);
        resetAM();
        const ele = autoClearRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) (ele as HTMLElement).click();
        dispatch(hideProgressLine());
    }

    useEffectAsync(async () => {
        const id = query.get("id");
        dispatch(showProgressLine());
        await getAccountById(id!);
        dispatch(hideProgressLine());
    }, [accessToCIM,accessToDocs])

    useEffect(() => {
        setIsEmailFieldEmpty(!getValues("email"));
    }, [watch("email")])

    useEffect(() => {
        setIsEmailFieldEmpty(!getValues("email"));
    }, [watch("email")])

    useEffect(() => {
        if (getValues("roles")) {
            setRolesNewAccount(getValues("roles"));
        }
    }, [watch("roles")])

    useEffect(() => {
        if (getValuesAM("roles")) {
            setRolesAccountManagerInvite(getValuesAM("roles"));
        }
    }, [watchAM("roles")])

    useEffect(() => {
        if (currentAccount?.accessToDocs) {
            setAccessToDocs(true);
        }
    }, [currentAccount])

    useEffect(() => {
        if (currentAccount?.accessToCIM) {
            setAccessToCIM(true);
        }
    }, [currentAccount])

    const menuItems = [
        <MenuItem
            key={0}
            onMouseEnter={onMouseEventHandler}
            onMouseLeave={onLeaveMouseEventHandler}
            onClick={() => {
                showModal(() => RenameAccountWizard(currentAccount!), {});
                setAnchorEl(null);
            }}>
            Rename account
        </MenuItem>];
    if (auth.loginInfo?.user?.role === "SUPER") {
        menuItems.push(
            <MenuItem
                key={1}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => {
                    showModal(() => RemoveAccountModal(currentAccount?.name!, currentAccount?.id!), {})
                    setAnchorEl(null);
                }}>
                <span style={{ color: 'red' }}>Remove account</span>
            </MenuItem>
        );
    }

    function RoleSelector(props: {
        roleState: string[], fieldErrors: FieldError[],
        register: () => {}, getValues: () => {}
    }) {

        return <div className="invite-item-height">
            <Select
                size="small"
                placeholder={"Select role(s)"}
                value={props.roleState}
                displayEmpty={true}
                renderValue={roleMultiSelectRenderValue}
                multiple={true}
                className="select-role invite-item-height"
                color="secondary"
                style={{backgroundColor: "white", color: theme.palette.text.primary}}
                {...props.register()}
            >
                {auth.loginInfo?.user?.role === "SUPER" &&
                    <MenuItem
                        value={"ADMIN"}
                        onMouseEnter={onMouseEventHandler}
                        onMouseLeave={onLeaveMouseEventHandler}>
                        <input type={"checkbox"} readOnly={true}
                               checked={props.roleState?.some(role => role==="ADMIN")} style={{marginRight:10}}/>Administrator</MenuItem>
                }
                <MenuItem
                    value={"PM"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="PM")} style={{marginRight:10}}/>Project Manager</MenuItem>
                <MenuItem
                    value={"FM"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="FM")} style={{marginRight:10}}/>File Manager</MenuItem>
                <MenuItem
                    value={"ANONYMIZE"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="ANONYMIZE")} style={{marginRight:10}}/>Anonymize</MenuItem>
                <MenuItem
                    value={"REDACTION"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="REDACTION")} style={{marginRight:10}}/>Redaction</MenuItem>
                <MenuItem
                    value={"USER"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="USER")} style={{marginRight:10}}/>Member</MenuItem>           
                    {accessToCIM &&  Object.keys(CIM_ROLES).map((roleKey)=>{
                        return <MenuItem
                        value={roleKey}
                        onMouseEnter={onMouseEventHandler}
                        onMouseLeave={onLeaveMouseEventHandler}>
                        <input type={"checkbox"} readOnly={true}
                            checked={props.roleState?.some(role => role===roleKey)} style={{marginRight:10}}/>{CIM_ROLES[roleKey as CIMRoleKey]}</MenuItem>
                    })  
                    }
                <MenuItem
                    value={"VIEW"}
                    onMouseEnter={onMouseEventHandler}
                    onMouseLeave={onLeaveMouseEventHandler}>
                    <input type={"checkbox"} readOnly={true}
                           checked={props.roleState?.some(role => role==="VIEW")} style={{marginRight:10}}/>View Only</MenuItem>
            </Select>
            {props.getValues() && props.fieldErrors &&
                <div style={{marginLeft: 30}}><ErrorHelper helperText="Role is required"/></div>}
        </div>
    }

    const handleDocsAccessChange = () => {
        if (currentAccount?.id!=undefined) {
            editAccountAccessToDocs(currentAccount.id, !accessToDocs)
                .then(() => {
                    // the setTimeout is a UI improvement to give the toggle time to animate the switch
                    setTimeout(() => setAccessToDocs(!accessToDocs), 300);
                });
        }
    }

    function DocsAccessSelector() {
        return <div className="invite-item-height">
            Account can access Docs
            <div className="toggle-switch" >
                <input type={"checkbox"} className="checkbox"
                       name="accessToDocs" id="accessToDocs" defaultChecked={accessToDocs} onChange={handleDocsAccessChange}/>
                <label className="label" htmlFor="accessToDocs" >
                    <span className="inner"/>
                    <span className="switch" />
                </label>
            </div>
        </div>
    }

    const handleCIMAccessChange = () => {
        if (currentAccount?.id!=undefined) {
            editAccountAccessToCIM(currentAccount.id, !accessToCIM)
                .then(() => {
                    setTimeout(() => setAccessToCIM(!accessToCIM), 300);
                });
        }
    }

    function CIMAccessSelector() {
        return <div className="invite-item-height">
            Account can access CIM
            <div className="toggle-switch" >
                <input type={"checkbox"} className="checkbox"
                       name="accessToCIM" id="accessToCIM" defaultChecked={accessToCIM} onChange={handleCIMAccessChange}/>
                <label className="label" htmlFor="accessToCIM" >
                    <span className="inner"/>
                    <span className="switch" />
                </label>
            </div>
        </div>
    }

    return <div style={{ flexGrow: 1, overflowY: "hidden" }}>
        <AccountModal>
            <div className="single-account-wrapper">
                <div className="single-account-header" >
                    <img alt="back" src={baseline_arrow_back} className="header-button" onClick={() => nav(-1)} />
                    <span className="single-account-name" >{currentAccount?.name}</span>
                    <MoreActionsComponent
                        id={currentAccount?.id!}
                        menuItems={menuItems}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        menuHorizontalPosition="right"
                    />
                </div>
                <div className="outer-invite-box" style = {{height: auth.loginInfo?.user?.role === "SUPER" ? 320 : 132}}>
                    <div className="inner-invite-box">
                        <p>Invite users to this account by email </p>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper" >
                            <div className="invite-item-height">
                                <TextField
                                    error={!!errors.email}
                                    size="small"
                                    variant="outlined"
                                    id="outlined-error-helper-text"
                                    placeholder="Enter email address"
                                    {...register("email", { required: getValues("roles") ? true : false, pattern: signInExpressions.emailExpression })}
                                    inputProps={{ style: { fontSize: 17 } }}
                                    className="invite-textfield"
                                    InputProps={!isEmailFieldEmpty ? {
                                        style: { height: 44 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => resetField("email")}
                                                >
                                                    <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    } : { style: { height: 44 } }}
                                />
                                {errors.email && errors.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                                {errors.email && errors.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                            </div>
                            <RoleSelector roleState={rolesNewAccount} fieldErrors={errors.roles!}
                                          getValues={() => getValues("roles")} register={() => register("roles", { required: true })}
                            />
                            <Button type="submit" color="secondary"
                                variant="contained" className="send-invite-button"
                                disabled={rolesNewAccount.length===0}
                                >
                                SEND INVITE
                            </Button>
                        </form>
                    </div>
                    {auth.loginInfo?.user?.role === "SUPER" && <div className="inner-invite-box" style={{ marginTop: 8 }}>
                        <p>Or add an existing user in the database to this account </p>
                        <form onSubmit={handleSubmitAM(onSubmitAM)} className="form-wrapper" >
                            <div className="invite-item-height">
                                <Controller
                                    name="email"
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            ref={autoClearRef}
                                            disablePortal
                                            size="small"
                                            id="combo-box-demo"
                                            noOptionsText="No matches found"
                                            className="invite-textfield"
                                            options={users?.accountManagers ?? []}
                                            getOptionLabel={option => option.email}
                                            renderInput={(params) => <TextField {...params} error={!!errorsAM.email} placeholder="Search by user name or email" required={true}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon color="secondary" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} style={{ height: 32, margin: "8px 0" }} key={option.id}>
                                                    <Avatar sx={{
                                                        backgroundColor: option.tenant?.user?.color! ?? "var(--normalBlue)",
                                                        width: 24, height: 24, fontSize: 14
                                                    }}>
                                                        {getUserInitials(option.name)}
                                                    </Avatar>

                                                    <span style={{ marginLeft: 12 }}>{option.name}</span>
                                                    {option.name && <span>&nbsp;-&nbsp;</span>}
                                                    <span>{option.email}</span>

                                                </Box>
                                            )}
                                            onChange={(_, data) => {
                                                onChange(data?.email);
                                                return data?.email;
                                            }}
                                        />
                                    )}
                                    control={controlAM}
                                />

                                {errorsAM.email && errorsAM.email.type === "required" && <ErrorHelper helperText={EmailErrors.EMPTY} />}
                                {errorsAM.email && errorsAM.email.type === "pattern" && <ErrorHelper helperText={EmailErrors.INVALID} />}
                            </div>
                            <RoleSelector roleState={rolesAccountManagerInvite} fieldErrors={errorsAM.roles!}
                                          getValues={() => getValuesAM("roles")} register={() => registerAM("roles", { required: true })}
                            />
                            <Button type="submit" color="secondary"
                                variant="contained" className="send-invite-button"
                                disabled={rolesAccountManagerInvite.length === 0}
                                >
                                ADD USER
                            </Button>
                        </form>
                    </div>}
                    {auth.loginInfo?.user?.role === "SUPER" && <div className="inner-invite-box" style={{ marginTop: 14 }}>
                        <DocsAccessSelector />
                    </div>}
                    {auth.loginInfo?.user?.role === "SUPER" && <div className="inner-invite-box" style={{ marginTop: 14 }}>
                        <CIMAccessSelector />
                    </div>}
                </div>
                {users?.projectManagers.length === 0 && users?.members.length === 0 && users?.admins.length === 0 && users?.redactors.length === 0 && users?.anonymizers.length === 0?
                    <p style={{ fontSize: 15 }}>
                        There are no users invited yet. You can invite users by entering their email address into the field above and assign them a role.
                    </p> :
                    <div style={{ maxHeight: "calc(100vh - 548px)", overflowY: "auto", marginTop: 16, maxWidth: "976px" }}>
                            <RoleTableInAccount key="ADMIN"
                                account={currentAccount!} isSuper={auth.loginInfo?.user?.role === "SUPER"} isDisabled={false} />
                    </div>
                }
            </div>
        </AccountModal>
    </div>
}
